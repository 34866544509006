import React from "react";
import { ImgV2 } from "../../ImgV2";
import { ButtonV2 } from "../../ButtonV2";
import { Heading } from "../../Heading";
import { AppWrapper } from "../AppWrapper/AppWrapper";
export const PlatformCard = ({ subTitle, title, buttonStyle, cardLogoImage, buttonLink, buttonLabel, leftIconUrl, rightIconUrl, leftImageLink, rightImageLink }) => (React.createElement("div", { className: "platform-card_wrapper" },
    React.createElement("div", { className: "platform-card_wrapper__image_and_text_wrapper" },
        React.createElement("div", { className: "image_and_text_wrapper__imageWrapper" },
            React.createElement(ImgV2, { src: cardLogoImage, alt: title })),
        React.createElement("div", { className: "image_and_text_wrapper__textWrapper" },
            React.createElement(Heading, { design: "h4-lp" }, title),
            React.createElement("p", { className: "caption2-regular" }, subTitle))),
    React.createElement("div", { className: "platform-card_wrapper__button_and_apps_wrapper" },
        React.createElement(ButtonV2, { href: buttonLink, design: buttonStyle }, buttonLabel),
        (leftIconUrl && rightIconUrl) && React.createElement(AppWrapper, { leftIconUrl: leftIconUrl, rightIconUrl: rightIconUrl, leftImageLink: leftImageLink, rightImageLink: rightImageLink }))));
