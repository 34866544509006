import React from "react";
import { Container, ContainerFW } from "../global";
import { getClassConditionalAppend, getColors, getContainerClassPerMaxWidth } from "../../utils";
import { PlatformCard } from "./PlatformCard/PlatformCard";
export const M36PlatformsDownload = ({ subTitle, title, backgroundColor, containerMaxWidth, titleAlignment, titleColor, subTitleAlignment, subTitleColor, cards, leftIconUrl, rightIconUrl }) => {
    const { bgColor } = getColors(backgroundColor);
    const containerMaxWidthClass = getContainerClassPerMaxWidth(containerMaxWidth);
    const containerExtraClass = getClassConditionalAppend(containerMaxWidthClass);
    return (React.createElement(ContainerFW, { className: containerExtraClass, backgroundColor: bgColor },
        React.createElement(Container, { className: containerExtraClass },
            React.createElement("div", { className: "platforms-download-root" },
                React.createElement("div", { className: "platforms-download-root__textWrapper" },
                    React.createElement("h1", { className: "textWrapper__title", style: { "--title-text-alignment": titleAlignment, "--title-text-color": titleColor } }, title),
                    React.createElement("p", { className: "copy-lp", style: { "--subTitle-text-alignment": subTitleAlignment, "--subTitle-text-color": subTitleColor } }, subTitle)),
                React.createElement("div", { className: "platforms-download-root__cardsWrapper" }, Array.isArray(cards) && cards.map((a) => (React.createElement(PlatformCard, { key: `unique${a.title}`, ...a, leftIconUrl: leftIconUrl, rightIconUrl: rightIconUrl }))))))));
};
