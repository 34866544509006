import React, { useMemo } from "react";
import { ButtonV2 } from "../../ButtonV2";
export const Disclaimer = ({ buttonLabel, buttonUrl, textDisclaimer, openInNewTab, buttonDesign }) => {
    const handleDisclaimerInfo = useMemo(() => {
        if (textDisclaimer || buttonLabel) {
            return (React.createElement("div", { className: "disclaimer-wrapper" },
                textDisclaimer && React.createElement("p", { className: "disclaimer-wrapper__comment" }, textDisclaimer),
                (buttonLabel && buttonUrl) && React.createElement(ButtonV2, { design: buttonDesign, href: buttonUrl, openInNewTab: openInNewTab }, buttonLabel)));
        }
        return React.createElement(React.Fragment, null);
    }, [buttonDesign, buttonLabel, buttonUrl, openInNewTab, textDisclaimer]);
    return (React.createElement(React.Fragment, null, handleDisclaimerInfo));
};
