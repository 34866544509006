import React from "react";
import { Container, ContainerFW } from "../global";
import { getContainerClassPerMaxWidth, } from "../../utils";
const getContainerExtraClass = (containerMaxWidth) => {
    const containerMaxWidthClass = containerMaxWidth && getContainerClassPerMaxWidth(containerMaxWidth);
    return containerMaxWidthClass ? ` ${containerMaxWidthClass}` : "";
};
export const M37MegaHeading = ({ title1, title2, subtitle, backgroundColor, containerMaxWidth, }) => {
    const containerExtraClass = getContainerExtraClass(containerMaxWidth);
    return (React.createElement(ContainerFW, { backgroundColor: backgroundColor, className: containerExtraClass },
        React.createElement(Container, { className: containerExtraClass },
            React.createElement("div", { className: `mega-heading ${containerExtraClass || ""}` },
                title1 && (React.createElement(SingleHeading, { className: "mega-heading__title mega-heading__title1", ...title1 })),
                title2 && (React.createElement(SingleHeading, { className: "mega-heading__title mega-heading__title2", ...title2 })),
                subtitle && (React.createElement(SingleHeading, { className: "mega-heading__title mega-heading__subtitle", ...subtitle }))))));
};
const SingleHeading = ({ className, text, colorType, color1, color2, alignment, tag, }) => {
    const HtmlTag = tag || "h2";
    const extrClassName = `mega-heading__title--style--${colorType}`;
    return (React.createElement(HtmlTag, { className: `${className} ${extrClassName}`, style: {
            "--color1": color1,
            "--color2": color2,
            "--alignment": alignment,
        } }, text));
};
