import React, { useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { getClassConditionalAppend, getColors, getContainerClassPerMaxWidth } from "../../utils";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { Disclaimer } from "./Disclaimer/Disclaimer";
import { Slides } from "./Slides/Slides";
export const M34HowItWorks = ({ slides, title, titleTitleAlignment, subTitle, subTitleTitleAlignment, subTitleColor, backgroundColor, buttonLabel, buttonUrl, textDisclaimer, openInNewTab, titleColor, buttonStyle, containerMaxWidth }) => {
    const { bgColor } = getColors(backgroundColor);
    const containerMaxWidthClass = getContainerClassPerMaxWidth(containerMaxWidth);
    const containerExtraClass = getClassConditionalAppend(containerMaxWidthClass);
    const handleSliderItems = useMemo(() => {
        if (slides.length > 4) {
            return 4.2;
        }
        return slides.length;
    }, [slides.length]);
    return (React.createElement("div", { className: "how-it-works-root" },
        React.createElement(ContainerFW, { className: containerExtraClass, backgroundColor: bgColor },
            React.createElement(Container, { className: containerExtraClass },
                React.createElement(Heading, { design: "h3-semi-bold", style: { "--title-text-alignment": titleTitleAlignment, "--title-text-color": titleColor } }, title),
                subTitle && React.createElement("p", { className: "copy-lp", style: { "--subTitle-text-alignment": subTitleTitleAlignment, "--subTitle-text-color": subTitleColor } }, subTitle),
                React.createElement("div", { className: "how-it-works-root__contentWrapper" },
                    React.createElement(Swiper, { breakpoints: {
                            375: {
                                slidesPerView: 1.25,
                                spaceBetween: 10
                            },
                            601: {
                                slidesPerView: 2.4,
                                spaceBetween: 10
                            },
                            1025: {
                                slidesPerView: handleSliderItems,
                                spaceBetween: 10
                            }
                        } }, Array.isArray(slides) && slides.map((slide, index) => (React.createElement(SwiperSlide, { key: `card-slide-${slide.comments}` },
                        React.createElement(Slides, { slideNumber: index + 1, comments: slide.comments }))))),
                    React.createElement(Disclaimer, { buttonDesign: buttonStyle, buttonLabel: buttonLabel, buttonUrl: buttonUrl, textDisclaimer: textDisclaimer, openInNewTab: openInNewTab }))))));
};
