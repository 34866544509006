import React, { useMemo } from "react";
import { getClassConditionalAppend, getColors, getContainerClassPerMaxWidth } from "../../utils";
import { Container, ContainerFW } from "../global";
import { FreeContentDisplay } from "./FreeContentDisplay/FreeContentDisplay";
import { StatSection } from "./StatSection/StatSection";
import { TradingViewSection } from "./TradingViewSection/TradingViewSection";
export const M38StatsAndTrustPilot = ({ stats, backgroundColor, containerMaxWidth, trustPilotScript, tradingViewEvaluation, tradingViewLogoUrl, tradingViewMarks, showTradingViewSection, borderAroundTrustPilot }) => {
    const { bgColor, textColor } = getColors(backgroundColor);
    const containerMaxWidthClass = getContainerClassPerMaxWidth(containerMaxWidth);
    const containerExtraClass = getClassConditionalAppend(containerMaxWidthClass);
    const HandleContainerWithBorder = useMemo(() => {
        if (bgColor === "#FFFFFF") {
            return React.createElement("div", { className: "brokerWrapper__colouredBorder" });
        }
        return React.createElement(React.Fragment, null);
    }, [bgColor]);
    const WrapperWithBorder = ({ children }) => {
        if (borderAroundTrustPilot) {
            return React.createElement("div", { className: "stats-and-trust-pilot-root__brokerWrapper with-border-around-TP" }, children);
        }
        return React.createElement("div", { className: "stats-and-trust-pilot-root__brokerWrapper" }, children);
    };
    return (React.createElement(ContainerFW, { className: containerExtraClass, backgroundColor: bgColor },
        React.createElement(Container, { className: containerExtraClass },
            React.createElement("div", { className: "stats-and-trust-pilot-root" },
                React.createElement("div", { className: "stats-and-trust-pilot-root__statsWrapper" }, Array.isArray(stats) && stats.map((stat) => (React.createElement(StatSection, { key: `unique${stat.title}`, ...stat, textColor: textColor })))),
                React.createElement(WrapperWithBorder, null,
                    showTradingViewSection && React.createElement(TradingViewSection, { evaluation: tradingViewEvaluation, logoUrl: tradingViewLogoUrl, marks: tradingViewMarks, textColor: textColor }),
                    showTradingViewSection && trustPilotScript && React.createElement("div", { className: "brokerWrapper__pipeSeparator" }),
                    trustPilotScript && React.createElement(FreeContentDisplay, { scriptContent: trustPilotScript })))),
        HandleContainerWithBorder));
};
